import { memo } from 'react'

const Loading = () => {
  return (
    <svg
      className="animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: 'auto', background: 'transparent', display: 'block', shapeRendering: 'auto' }}
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="white"
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
      />
    </svg>
  )
}

export default memo(Loading)
