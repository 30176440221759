import { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { requestTimeout } from 'shared/utils'
import { RootState } from 'store'
import { ITimer } from 'types'
import { useTranslation } from 'react-i18next'
import Loading from 'icons/Loading'

const LoadingToast = () => {
  const isShow = useSelector((state: RootState) => state.app.showLoading)
  const [visible, setVisible] = useState(isShow)
  const { t } = useTranslation()

  useEffect(() => {
    let timer: ITimer
    // delay 200ms for hideout transition to work
    if (!isShow) timer = requestTimeout(() => setVisible(false), 200)
    else setVisible(true)

    return () => timer?.clear()
  }, [isShow])

  return (
    <div
      className={`absolute bg-black bg-opacity-60 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded large:rounded-lg p-3 large:p-5 flex flex-col items-center transition-opacity duration-200
      w-[133px] large:w-[165px]
      ${isShow ? 'opacity-100' : 'opacity-0'}
      ${visible ? 'visible' : 'invisible'}`}
      id="toast"
    >
      <div className="relative mb-2 w-7 h-7">
        <Loading />
      </div>
      <div className="text-white text-center text-normal large:text-large">{t('Đang tải...')}</div>
    </div>
  )
}

export default memo(LoadingToast)
